import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./tiendadashboard.css";
import icon_QR from "../Image/logo/qr-code_icon.png";
import icon_lupa from "../Image/icons/icon_lupa.png";
const TiendaDashBoard = () => {
  const [data, setData] = useState({
    ventasRealizadas: 0,
    enviosColectados: 0,
    pedidosEnLogistica: 0,
    pedidosEnRuta: 0,
    pedidosReprogramados: 0,
    pedidosCancelados: 0,
    pedidosEntregados: 0,

  });

  const [id_logis, setIdLogistica] = useState(""); // nombre logistica
  const [userId, setUserId] = useState(""); // id de usuario
  const [userNombre, setUserNombre] = useState(""); // nombre usuario
  const [id_tienda, setIdTienda] = useState(""); // id de tienda
  const [isLoading, setIsLoading] = useState(true); // estado para icono de carga
  const [qrInput, setQrInput] = useState(""); // estado para el input del QR
  const [resultData, setResultData] = useState(null); // estado para almacenar los resultados de la búsqueda
  const [error, setError] = useState(""); // estado para manejar errores

  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    setUserNombre(localStorage.getItem("userNombre"));
    setIdLogistica(localStorage.getItem("id_logis"));
    setIdTienda(localStorage.getItem("id_tienda"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      console.log('va a buscar datos de la tienda', id_tienda);
      try {
        const response = await fetch(
          "https://shipmaster.com.ar/backoffice/php/admindash/dashboardtienda.php",
          {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({ id_tienda }),
          }
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id_tienda) {
      fetchData();
    }
  }, [id_tienda]);

  const handleQrInputChange = (e) => {
    setQrInput(e.target.value);
  };

  const handleSearch = async () => {
    setResultData(null); // Limpiar resultados anteriores
    setError(""); // Limpiar errores

    try {
      const response = await fetch(
        "https://shipmaster.com.ar/backoffice/php/web/buscarQR.php",
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({ hashCode: qrInput, id_tienda: id_tienda }),
        }
      );

      console.log("Response:", response); // Verificar la respuesta

      if (!response.ok) {
        const errorMessage = await response.text(); // Obtener el mensaje de error
        throw new Error(`Error en la búsqueda: ${errorMessage}`);
      }

      const result = await response.json();
      if (result) {
        //setResultData(result);
        //console.log('result:', result)
        setResultData(result.data); // Accede a la propiedad 'data'
        console.log('result:', result.data); // <-- Y esta también, para ver solo el array
      } else {
        setError("No se encontraron datos para el QR ingresado");
      }
    } catch (error) {
      console.error("Error buscando QR:", error);
      setError(
        "Ocurrió un error al buscar el QR. Verifica la consola para más detalles."
      );
    }
  };

  const {
    ventasRealizadas,
    enviosColectados,
    pedidosEnLogistica,
    pedidosEnRuta,
    pedidosReprogramados,
    pedidosCancelados,
    pedidosEntregados,
    cambios,
  } = data;

  return (
    <div style={{ marginTop: '30px' }}>
      <div className="centro">
        <h4 className="centro">
          TIENDA Dash Board {id_tienda}
          <i
            className="fas fa-chart-bar icono"
            style={{ color: "#b87f05" }}
          ></i>
        </h4>
      </div>

      <div className="dashboard-container">
        {isLoading ? (
          <div className="loading-icon">
            <i
              className="fas fa-spinner fa-spin"
              style={{ fontSize: "50px", color: "#b87f05" }}
            ></i>
            <p>Cargando datos...</p>
          </div>
        ) : (
          <div>
            <div className="dashboard-grid">
              <div className="dashboard-card">
                <h3>Ventas Realizadas</h3>
                <p>{ventasRealizadas}</p>
              </div>
              <div className="dashboard-card">
                <h3>Envíos Colectados</h3>
                <p>{enviosColectados}</p>
              </div>
              <div className="dashboard-card">
                <h3>Pedidos en Logística</h3>
                <p>{pedidosEnLogistica}</p>
              </div>
              <div className="dashboard-card">
                <h3>Pedidos en Ruta</h3>
                <p>{pedidosEnRuta}</p>
              </div>
              <div className="dashboard-card">
                <h3>Pedidos Entregados</h3>
                <p>{pedidosEntregados}</p>
              </div>
              <div className="dashboard-card">
                <h3>Pedidos Reprogramados</h3>
                <p>{pedidosReprogramados}</p>
              </div>
              <div className="dashboard-card">
                <h3>Pedidos Cancelados</h3>
                <p>{pedidosCancelados}</p>
              </div>
              <div className="dashboard-card">
                <h3>Cambios</h3>
                <p>{cambios}</p>
              </div>
            </div>



          </div>
        )}
      </div>
      <div className="dashboard-rastreo">
        <p>Rastrear Envio</p>
        <label htmlFor="qrRastreo" className="qr-label">
          <img src={icon_QR} alt="Icono QR" className="qr-icon" /> Ingresa el
          QR:
        </label>
        <div className="qr-input-container">
          <input
            type="text"
            id="qrRastreo"
            className="qr-input"
            placeholder="Ejemplo: XQR123456"
            value={qrInput}
            onChange={handleQrInputChange} // Manejar cambios en el input
          />
          <button onClick={handleSearch} className="search-button">
            <img src={icon_lupa} alt="Buscar" className="search-icon" /> Buscar
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}{" "}
        {/* Mensaje de error 
        {resultData && (*/}

        {Array.isArray(resultData) && resultData.length > 0 ? (
          <div className="qr-label">
            <h3>Resultados de la Búsqueda:</h3>

            <div className="card-list"> {/* Contenedor para las cards */}
              {resultData.map((item, index) => (

                <div key={item.id_generar || index} className="card"> {/* Card individual */}{/* Clave única para cada elemento */}
                  <h4>
                    <strong>ID Local:</strong> {item.id_tienda}
                  </h4>
                  <h4>
                    <strong>ID Logística:</strong> {item.id_logistica} {/* Usa item.id_logistica */}
                  </h4>
                  <h4>
                    <strong>Dirección:</strong> {item.direccion} {item.piso && <><strong>Piso:</strong> {item.piso}</>}
                    {item.departamento && <><strong>Departamento:</strong> {item.departamento}</>}
                  </h4>
                  <h4>
                    <strong>Comuna:</strong> {item.comuna} <strong>Barrio:</strong> {item.barrio} <strong>CP:</strong> {item.cp}
                  </h4>
                  <h4>
                    <strong>Nombre Contacto:</strong> {item.nombreContacto} <strong>Teléfono:</strong> {item.telefono}
                  </h4>
                  <h4>
                    <strong>Email Contacto:</strong> {item.mailContacto}
                  </h4>
                  <h4>
                    <strong>Observaciones:</strong> {item.observaciones}
                  </h4>
                  <h4>
                    <strong>Fecha Venta:</strong> {item.fechayhora}
                    <strong>Descripción Estado:</strong> {item.estado_descripcion}
                  </h4>
                </div>

              ))}

            </div>
          </div>
        ) : (
          <p>No se encontraron resultados.</p>
        )
        }



        {/* )} */}









      </div>
      <footer className="dashboard-footer">
        <p>XQR - Experiencia y Tecnología para logísticas</p>
      </footer>
    </div>
  );
};

export default TiendaDashBoard;
