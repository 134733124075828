import React, { useEffect, useState } from "react";
import JsonData from "../../data/data.json";
import Tienda from "../dashboards/tienda";

import "./usuarios.css";

const PortalTienda = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [userId, setUserId] = useState(''); // id de usuario
  const [userNombre, setUserNombre] = useState(''); // nombre usuario
  const [id_logis, setidLogistica] = useState(''); // nombre logistica 
  const [nameLogistica, setUserLogistica] = useState(''); // nombre logistica
  const [nameTienda, setUserNombreTienda] = useState('');
  const [direccionTienda, setdireccionTienda] = useState('');
  const [id_tienda, setidTienda] = useState('');
  const [telefonoTienda, settelefonoTienda] = useState('');
  //const [userMail, setUserMail] = useState("");  // mail de usuario
  
  useEffect(() => {
    setUserId(localStorage.getItem('userId'));
    setUserNombre(localStorage.getItem('userNombre'));
    setidLogistica(localStorage.getItem('id_logis'));
    setUserLogistica(localStorage.getItem('nombre_logistica'));
    setUserNombreTienda(localStorage.getItem('name_tienda'));
    setdireccionTienda(localStorage.getItem('direccion_tienda'));
    settelefonoTienda(localStorage.getItem('telefono_tienda'));
    setidTienda(localStorage.getItem('id_tienda'));
    //setUserMail(localStorage.getItem("userMail"));
    console.log('userId:' + userId);
    setLandingPageData(JsonData);
  }, [userId, userNombre]);

  return (
    <div className='fondodash' style={{ overflowY: 'hidden' }}>
      <div className='centrado' style={{margin:'-40px 0'}}>
        <br />
        <br />
        <br />
        <br />
        <div className='centro'>
          <Tienda data={landingPageData.Tienda} />
        </div>
      </div>
    </div>
  );
};
export default PortalTienda;
