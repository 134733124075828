import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react"; // Importamos la librería para generar QR
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import "./flexscreen.css"; // Tus estilos

const FlexScreen = () => {
  const [qrFlexData, setQrFlexData] = useState([]); // Cambiar a arreglo
  const [id_logis, setId_logis] = useState(null);
  const [message, setMessage] = useState(""); // Estado para manejar el mensaje
  const navigate = useNavigate(); // Inicializas el hook

  // Recuperar `id_logis` del localStorage al montar el componente
  useEffect(() => {
    const storedIdLogis = localStorage.getItem("id_logis");
    if (storedIdLogis) {
      setId_logis(storedIdLogis);
    } else {
      console.error("No se encontró id_logis en localStorage.");
    }
  }, []);

  const handleBack = () => {
    // Redirige a la pantalla nueva cuando se presiona el botón
    navigate("/PortalLogistica", { state: { id_logis } });
  };

  // Ejecutar fetch cuando `id_logis` cambie y tenga un valor válido
  useEffect(() => {
    if (!id_logis) return;

    const fetchFlexData = async () => {
      try {
        const response = await fetch(
          "https://shipmaster.com.ar/backoffice/php/admindash/buscarFlex.php",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id_logis }),
          }
        );

        const result = await response.json();
        console.log("Datos recibidos:", result);

        // Si hay un mensaje en la respuesta, mostrarlo
        if (result.message) {
          setMessage(result.message);
          setQrFlexData([]); // Limpiar los datos del QR
        } else {
          // Si hay datos, establecer el estado con el arreglo de registros
          setQrFlexData(result); // Guardar todos los datos recibidos
          setMessage(""); // Limpiar mensaje de error
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchFlexData();
  }, [id_logis]);

  // Si no hay datos, mostrar el mensaje de "no hay registros"
  if (qrFlexData.length === 0 && message) {
    return (
      <div className="flexscreen-container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 style={{ color: "#000", textAlign: "center" }}>QR Mercado Flex</h1>
        <p style={{ color: "#000", textAlign: "center" }}>
          Información de los "Paquetes Flex".
        </p>
        <div style={{ color: "red", textAlign: "center", fontSize: "18px" }}>
          {message}
        </div>
      </div>
    );
  }

  // Si los datos están cargando o no están disponibles, muestra "Cargando..."
  if (qrFlexData.length === 0) {
    return <div>Cargando datos...</div>;
  }

  return (
    <div className="flexscreen-container">
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1 style={{ color: "#000", textAlign: "center" }}>QR Mercado Flex</h1>
      <p style={{ color: "#000", textAlign: "center" }}>
        Información de los "Paquetes Flex".
      </p>
      <div>
        <button onClick={handleBack}>Volver</button>
      </div>
      <br />

      {/* Aquí aplicamos una grilla para mostrar 3 registros por renglón */}
      <div className="grid-container">
        {qrFlexData.map((item, index) => (
          <div key={index} className="card">
            <div className="card-body">
              <p className="centered-text">
                <strong className="colorverde">
                  {item.id.slice(0, -4)}{" "}
                  <span style={{ fontSize: "larger", fontWeight: "bold" }}>
                    {item.id.slice(-4)}
                  </span>
                </strong>
              </p>
              <p>
                <strong className="colorverde">ID Sender:</strong> {item.sender_id}
              </p>
              <p>
                <strong className="colorverde">Estado:</strong> {item.security_digit}
              </p>

              {item.tienda && (
                <>
                  <h4 style={{ marginTop: "10px", color: "#6b8e23" }}>
                    Datos de la Tienda
                  </h4>
                  <p>
                    <strong className="colorverde">Nombre:</strong>{" "}
                    {item.tienda.nombre_tienda?.toUpperCase()}
                  </p>
                  <p>
                    <strong className="colorverde">Dirección:</strong>{" "}
                    {item.tienda.direccion_tienda}
                  </p>
                  <p>
                    <strong className="colorverde">Email:</strong>{" "}
                    {item.tienda.mail_tienda}
                  </p>
                  <p>
                    <strong className="colorverde">Teléfono:</strong>{" "}
                    {item.tienda.telefono_tienda}
                  </p>
                  <p>
                    <strong className="colorverde">Contacto:</strong>{" "}
                    {item.tienda.contacto_tienda}
                  </p>
                </>
              )}
            </div>
            <div className="qr-container">
              {/* Solo genera el QR si los datos están disponibles */}
              {item.sender_id && item.hash_code && item.security_digit ? (
                <QRCode
                  value={JSON.stringify({
                    id: item.hash_code,
                    sender_id: item.sender_id,
                    hash_code: item.hash_code,
                    security_digit: item.security_digit,
                  })}
                  size={150}
                  renderAs="svg"
                 
                  level="H" // Corrección de errores al nivel más alto
                />
              ) : (
                <p>Datos no disponibles para generar el QR.</p>
              )}
            </div>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlexScreen;
