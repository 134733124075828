import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "react-loading";
import "./registros.css";

const Registros = () => {
  const [loading, setLoading] = useState(false);
  const [hwidLog, setHwidLog] = useState("");
  const [tabla, setTabla] = useState([]);
  const [eliminado, setEliminado] = useState([]);
  const [setModificado] = useState([]);
  const [idkey, setKey] = useState([]);
  const userName = localStorage.getItem("userName");
  const macData = localStorage.getItem("macData");
  const [totalRegistros, setTotalRegistros] = useState(0);
  //const desde = localStorage.getItem("fechaDesde");
  //const hasta = localStorage.getItem("fechaHasta");
  const [desde, setDesde] = useState(
    new Date(localStorage.getItem("fechaDesde"))
  );
  const [hasta, setHasta] = useState(
    new Date(localStorage.getItem("fechaHasta"))
  );

  useEffect(() => {
    //Recupero usuario y mac
    setHwidLog(localStorage.getItem("macData"));
    //////////////////////////////////////////////
  }, [desde, hasta, macData, userName]);
  /***************************************************************************************************** */
  const DatosRegxRango = async (res) => {
    setLoading(true);
    console.log("DATOS POR RANGO");
    console.log("VACIANDO TABLA");
    vaciarTabla();
    //fetch("http://localhost/shipmaster/php/graficas.php", {
    fetch("https://www.shipmaster.com.ar/backoffice/php/web/graficas.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ hwid: hwidLog, desd: desde, hast: hasta }),
    })
      .then((res) => res.json()) // Convertir la respuesta a un objeto JSON
      .then((data) => {
        const res = data;
        setTabla(res);
        console.log(res.result);
        console.log("TABLAx rango ==>> " + JSON.stringify(tabla));

        localStorage.setItem("fechaDesde", desde); //datauser
        localStorage.setItem("fechaHasta", hasta); //data mac user
        //setTabla(data);
        if (tabla.length > 0) {
          setTabla((prevTabla) =>
            prevTabla.slice().sort((a, b) => b.id - a.id)
          );
        }

        setTotalRegistros(data.length);
        //DatosGrafTemp();
        //GrafTemperatura();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  /***************************************************************************************************** */

  const EliminoReg = async () => {
    console.log("Registro a Eliminar:" + idkey);
    if (userName.toLowerCase() === "test") {
      // Si el usuario es "test", se deshabilita el botón.
      console.log("El usuario 'test', NO puede eliminar el registro.");
      alert(
        "El usuario 'test', NO PUEDE eliminar el registro. Es solo para conocer la experiencia del equipo y el sistema shipmaster"
      );
      return;
    }
    //alert("El usuario no es 'test', PUEDE eliminar el registro.");
    try {
      //const resp = await fetch("http://localhost/shipmaster/php/eliminoreg.php", {
      const resp = await fetch(
        "https://www.shipmaster.com.ar/php/eliminoreg.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: idkey }),
        }
      );

      const res = await resp.json();
      console.log(res.result);
      setEliminado(res);
      console.log(
        "REGISTRO BOLETEADO = Eliminado==>> " + JSON.stringify(eliminado)
      );
    } catch (error) {
      console.log(error);
    }
    console.log("VACIANDO TABLA");
    vaciarTabla();
    console.log("LLEnando  TABLA");
    DatosRegxRango();
  };

  const vaciarTabla = () => {
    setTabla([]); // Vaciar la tabla
  };

  const ModificoReg = async () => {
    console.log("Registro a MODIFICAR:" + idkey);
    if (userName.toLowerCase() === "test") {
      // Si el usuario es "test", se deshabilita el botón.
      console.log("El usuario 'test', NO puede modificar el registro.");
      alert(
        "El usuario 'test', NO PUEDE modificar el registro. Es solo para conocer la experiencia del equipo y el sistema shipmaster"
      );
      return;
    } else {
      alert("El usuario no es 'test', PUEDE modificar el registro.");
      try {
        const resp = await fetch(
          "https://www.shipmaster.com.ar/php/ModificoReg",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: idkey }),
          }
        );
        const res = await resp.json();
        console.log(res.result);
        setModificado(res.result);
        console.log("Modificado==>> " + JSON.stringify(eliminado));
      } catch (error) {
        console.log(error);
      }
      DatosRegxRango();
    }
  };

  return (
    <div>
      <div>
        <h4>
          REGISTROS
          <i
            className="fas fa-database"
            style={{ paddingLeft: "20px", color: "#6b6b6b" }}
          ></i>
        </h4>
        <br />
        <div className="divdatepicker">
          <span className="spandsd">{" Desde:"}</span>
          <DatePicker
            selected={desde}
            onChange={(date) => setDesde(date)}
            dateFormat="yyyy/MM/dd"
            className="date-picker" // Aplica la clase CSS aquí
          />
          <span className="spanhst">{" Hasta:"}</span>
          <DatePicker
            selected={hasta}
            onChange={(date) => setHasta(date)}
            dateFormat="yyyy/MM/dd"
            className="date-picker" // Aplica la clase CSS aquí
          />
          {"  "}
          {"  "}
          <button className="btnsbuscar" onClick={DatosRegxRango}>
            Buscar
          </button>
        </div>
      </div>
      <div>
        <p>
          Se encontraron <span className="resaltar">{totalRegistros} </span>{" "}
          registros
        </p>
      </div>
      <div>
        {loading ? (
          <div className="divspin">
            <Loading type="spin" color="#0592F9" height={50} width={50} />
          </div>
        ) : (
          <div className="containerReg">
            <br />
            <h4>Datos Recibidos</h4>
            <table className="centered-table">
              <tbody>
                {tabla.map((each) => (
                  <tr>
                    <tr>
                      <th>id</th>
                      <td key={each.id}> {each.id}</td>
                      <th>MAC</th>
                      <td> {each.mac}</td>
                      <th>IP</th>
                      <td
                        className={
                          userName.toLowerCase() === "test" ? "no-ip" : null
                        }
                      >
                        {/* {userName.toLowerCase() !== "test" && each.ip } */}
                        {userName.toLowerCase() !== "test"
                          ? each.ip
                          : "0.0.0.0"}
                      </td>

                      <th>Fecha Hora Equipo</th>
                      <td colSpan="3">
                        {" "}
                        {each.anio}/{each.mes}/{each.dia} {each.hora}:
                        {each.minuto}
                      </td>
                      <th>Horario</th>
                      <td
                        className={
                          each.au === "1" ? "green-irriga" : "red-irriga"
                        }
                      >
                        <strong style={{ color: "black" }}>
                          {each.au === "1" ? "Manual" : "Automatico"}
                        </strong>
                      </td>
                      {/* <th >accion</th>
                    <td> {each.accion}</td> */}
                    </tr>
                    <tr>
                      <th>Luz</th>
                      <td> {each.iluz} %</td>
                      <th>Seteo Luz</th>
                      <td> {each.cluz} %</td>
                      <th>Temperatura</th>
                      <td> {each.temp} °</td>
                      <th>Humedad</th>
                      <td> {each.hum} %</td>
                      <th>Temp. SN</th>
                      <td> {each.temp_sn} °</td>
                      <th>ppm</th>
                      <td> {each.ppm}</td>
                    </tr>
                    <tr>
                      <th>EC</th>
                      <td> {each.ec}</td>
                      <th>Seteo EC</th>
                      <td> {each.Set_ec}</td>
                      <th>Irriga</th>
                      <td
                        className={
                          each.erelay1 === "1" ? "green-irriga" : "red-irriga"
                        }
                      >
                        <strong style={{ color: "white" }}>
                          {each.erelay1 === "1" ? "ON" : "OFF"}
                        </strong>
                      </td>
                      <th>Oxigena</th>
                      <td
                        className={
                          each.erelay2 === "1" ? "green-irriga" : "red-irriga"
                        }
                      >
                        <strong style={{ color: "white" }}>
                          {each.erelay2 === "1" ? "ON" : "OFF"}
                        </strong>
                      </td>
                      <th>Tiempo Irriga</th>
                      <td> {each.irriga} min/hora</td>
                      <th>Nivel de Tanque</th>
                      <td> {each.tank} %</td>
                    </tr>
                    <tr
                      style={{
                        borderBottom: "2px solid black",
                        width: "100%",
                      }}
                    >
                      <th>e-mail</th>
                      <td colSpan="4"> {each.email}</td>
                      <th>Registrado</th>
                      <td colSpan="3"> {each.fechayhora}</td>

                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          value={each.id}
                          onMouseOver={(e) => setKey(e.target.value)}
                          onClick={EliminoReg}
                        >
                          Eliminar
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onMouseOver={(e) => setKey(e.target.value)}
                          value={each.id}
                          onClick={ModificoReg}
                        >
                          Modificar
                        </button>
                      </td>
                    </tr>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <br />
      <div style={{ backgroundColor: "#1CD10E" }}>
        <p style={{ textAlign: "center", fontSize: "24px", color: "#FFFFFF" }}>
          shipmaster - Tecnologia en shipmaster{" "}
        </p>
      </div>
    </div>
  );
};

export default Registros;
