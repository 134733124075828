import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import "./tiendadashboard.css";

Modal.setAppElement("#root");

const TiendaVentas = () => {
  const [userId, setUserId] = useState("");
  const [userNombre, setUserNombre] = useState("");
  const [nameLogistica, setUserLogistica] = useState("");
  const [nameTienda, setUserNombreTienda] = useState("");
  const [direccionTienda, setdireccionTienda] = useState("");
  const [id_tienda, setidTienda] = useState("");
  const [id_logis, setidLogistica] = useState("");
  const [telefonoTienda, settelefonoTienda] = useState("");
  const [logisticaData, setLogisticaData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [editData, setEditData] = useState({
    id_generar: "",
    direccion: "",
    altura: "",
    piso: "",
    departamento: "",
    barrio: "",
    comuna: "",
    localidad: "",
    cp: "",
    telefono: "",
    nombreContacto: "",
    mailContacto: "",
    observaciones: "",
    domicilioTipo: "",
    horarioEntrega: "",
  });

  const [isLoading, setIsLoading] = useState(true); // Nuevo estado
  const logoUrl = "https://shipmaster.com.ar/img/logo/xqr_qr.png"; // Asegúrate de que esta ruta sea válida

  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    setUserNombre(localStorage.getItem("userNombre"));
    setidLogistica(localStorage.getItem("id_logis"));
    setUserLogistica(localStorage.getItem("nombre_logistica"));
    setUserNombreTienda(localStorage.getItem("name_tienda"));
    setdireccionTienda(localStorage.getItem("direccion_tienda"));
    settelefonoTienda(localStorage.getItem("telefono_tienda"));
    setidTienda(localStorage.getItem("id_tienda"));
  }, []);

  useEffect(() => {
    if (id_tienda) {
      setIsLoading(true); // Empezar la carga
      fetch(
        `https://shipmaster.com.ar/backoffice/php/admindash/tiendaVentas.php?id_tienda=${id_tienda}`
      )
        .then((response) => response.json())
        .then((data) => {
          setLogisticaData(data);
          setIsLoading(false); // Terminar la carga
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false); // Terminar la carga incluso si hay error
        });
    }
  }, [id_tienda]);

  const handleRecordClick = (record) => {
    setSelectedRecord(record);
    setIsEditing(false);
  };

  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setIsEditing(true);
    setEditData({
      id_generar: record.id_generar,
      direccion: record.direccion,
      altura: record.altura,
      piso: record.piso,
      departamento: record.departamento,
      comuna: record.comuna,
      barrio: record.barrio,
      localidad: record.localidad,
      cp: record.cp,
      telefono: record.telefono,
      nombreContacto: record.nombreContacto,
      mailContacto: record.mailContacto,
      observaciones: record.observaciones,
      domicilioTipo: record.domicilioTipo,
      horarioEntrega: record.horarioEntrega,
    });
  };

  const closeModal = () => {
    setSelectedRecord(null);
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveChanges = () => {
    fetch(
      `https://shipmaster.com.ar/backoffice/php/admindash/updateTiendaVentas.php`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...editData,
          id_generar: selectedRecord.id_generar,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Registro actualizado:", data);

        fetch(
          `https://shipmaster.com.ar/backoffice/php/admindash/tiendaVentas.php?id_tienda=${id_tienda}`
        )
          .then((response) => response.json())
          .then((data) => setLogisticaData(data))
          .catch((error) =>
            console.error("Error fetching updated data:", error)
          );

        closeModal();
      })
      .catch((error) => console.error("Error actualizando registro:", error));
  };
  const handleSearch = () => {
    const filteredData = logisticaData.filter(
      (item) =>
        item.nombreContacto.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.id_generar.includes(searchQuery)
    );
    setLogisticaData(filteredData);
  };
  return (
    <div>
      <div className="centro" style={{ alignContent: "center" , marginTop: '80px' }}>
        <div>
          <h4 className="centro">
            Ventas{" "}
            <i
              className="fas fa-chart-bar icono"
              style={{ color: "#b87f05" }}
            ></i>
          </h4>
        </div>
      </div>
      <div>
        <input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Buscar por nombre o ID"
        />
        <button onClick={handleSearch}>Buscar</button>
      </div>
      <div className="dashboard-container">
        {isLoading ? ( // Mostrar el loading mientras se cargan los datos
          <div className="loading-icon">
            <i
              className="fas fa-spinner fa-spin"
              style={{ fontSize: "50px", color: "#b87f05" }}
            ></i>
            <p>Cargando datos...</p>
          </div>
        ) : (
          <div className="dashboard-box">
            <ul className="record-list">
              {logisticaData.map((item, index) => (
                <li key={index}>
                  <p>
                    ID Generar: {item.id_generar} - Nombre:{" "}
                    {item.nombreContacto} - Mail: {item.mailContacto}
                  </p>
                  <p>
                    Dirección: {item.direccion} {item.altura} -Piso: {item.piso}{" "}
                    -Departamento: {item.departamento}
                  </p>
                  <p>
                    Comuna: {item.comuna} - Barrio: {item.barrio}- Localidad:{" "}
                    {item.localidad}
                  </p>
                  <p>
                    Retirado: {item.fechacarga} - Entregado: {item.fechaentrega}
                  </p>
                  <p style={{ display: "inline-block", textAlign: "center" }}>
                    <span>Tracking: </span>
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      {item.hashCode.slice(0, -4)}
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          marginLeft: "1px", // Espacio entre el texto y los últimos 4 dígitos
                        }}
                      >
                        {item.hashCode.slice(-4)}
                      </span>
                    </span>
                  </p>
                  <p>
                    Estado: {item.estado} - {item.estadoDescripcion}
                  </p>
                  <div>
                    {item.estado === 1 && (
                      <button onClick={() => handleEditClick(item)}>
                        Editar
                      </button>
                    )}
                    <button onClick={() => handleRecordClick(item)}>
                      Ver Etiqueta
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <Modal
        isOpen={!!selectedRecord}
        onRequestClose={closeModal}
        contentLabel="Detalles del Registro"
        className="print-modal"
        style={{
          content: {
            width: "500px",
            height: "auto",
            margin: "auto",
            border: "2px solid black",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
          },
        }}
      >
        {selectedRecord && isEditing ? (
          <div className="record-edit-form">
            <h3>Editar Registro</h3>
            <form>
              <p>Id: {editData.id_generar}</p>
              <input
                name="direccion"
                value={editData.direccion}
                onChange={handleInputChange}
                placeholder="Dirección"
              />
              <input
                name="altura"
                value={editData.altura}
                onChange={handleInputChange}
                placeholder="Altura"
              />

              <input
                name="piso"
                value={editData.piso}
                onChange={handleInputChange}
                placeholder="Piso"
              />
              <input
                name="departamento"
                value={editData.departamento}
                onChange={handleInputChange}
                placeholder="Departamento"
              />
              <input
                name="comuna"
                value={editData.comuna}
                onChange={handleInputChange}
                placeholder="Comuna"
              />
              <input
                name="barrio"
                value={editData.barrio}
                onChange={handleInputChange}
                placeholder="Barrio"
              />
              <input
                name="localidad"
                value={editData.localidad}
                onChange={handleInputChange}
                placeholder="Localidad"
              />
              <input
                name="cp"
                value={editData.cp}
                onChange={handleInputChange}
                placeholder="CP"
              />
              <input
                name="telefono"
                value={editData.telefono}
                onChange={handleInputChange}
                placeholder="Teléfono"
              />
              <input
                name="nombreContacto"
                value={editData.nombreContacto}
                onChange={handleInputChange}
                placeholder="Nombre Contacto"
              />
              <input
                name="mailContacto"
                value={editData.mailContacto}
                onChange={handleInputChange}
                placeholder="Mail Contacto"
              />
              <input
                name="observaciones"
                value={editData.observaciones}
                onChange={handleInputChange}
                placeholder="Observaciones"
              />
              <input
                name="domicilioTipo"
                value={editData.domicilioTipo}
                onChange={handleInputChange}
                placeholder="Tipo de Domicilio"
              />
              <input
                name="horarioEntrega"
                value={editData.horarioEntrega}
                onChange={handleInputChange}
                placeholder="Horario de Entrega"
              />
            </form>
            <button onClick={handleSaveChanges}>Guardar Cambios</button>
            <button onClick={closeModal} className="btn-close">
              Cerrar
            </button>
          </div>
        ) : (
          selectedRecord && (
            <div
              className="record-details"
              style={{ maxHeight: "90vh", overflowY: "70%" }}
            >
              <h3 style={{ textAlign: "center" }}>
                <img
                  src="https://shipmaster.com.ar/img/logo/logoShipMaster.png"
                  alt="QR"
                  style={{ maxWidth: "30%", height: "auto", maxHeight: "50px" }}
                />{" "}
                XQR
              </h3>
              <div
                style={{
                  backgroundColor: "black",
                  color: "white",
                  textAlign: "center",
                  padding: "5px",
                  fontSize: "20px",
                }}
              >
                PEGAR EN UN LUGAR VISIBLE
              </div>
              <div className="record-details">
                <div>
                  <table style={{ width: "100%", tableLayout: "fixed" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            width: "40%",
                            paddingLeft: "10px",
                            marginTop: "10px",
                            verticalAlign: "center",
                            textAlign: "left",
                          }}
                        >
                          <br />
                          <QRCode
                            //value={JSON.stringify(selectedRecord.hashCtrl)}
                            value={JSON.stringify(selectedRecord.hashCode).slice(1, -1)} // Eliminar las comillas
                            size={150}
                            renderAs="svg"
                            level="H" // Corrección de errores al nivel más alto
                          />
                        </td>
                        <td
                          style={{
                            width: "60%",
                            paddingRight: "5px",
                            verticalAlign: "top",
                          }}
                        >
                          <h4 style={{ textAlign: "center" }}>ORIGEN</h4>
                          <p>
                            {nameTienda} - {id_tienda}
                          </p>
                          <p>Dirección: {direccionTienda}</p>
                          <p>Tel: {telefonoTienda}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr
                  style={{
                    border: "1px solid black",
                    width: "100%",
                    background: "black",
                  }}
                />
                <div>
                  <h4 style={{ textAlign: "center" }}>DESTINO</h4>
                  <h5 style={{ textAlign: "center" }}>
                    {selectedRecord.direccion} {selectedRecord.altura} - Piso:{" "}
                    {selectedRecord.piso} - Dpto: {selectedRecord.departamento}
                  </h5>
                  <h5 style={{ textAlign: "center" }}>
                    Comuna: {selectedRecord.comuna} - Barrio:{" "}
                    {selectedRecord.barrio} - Localidad :{" "}
                    {selectedRecord.localidad}
                  </h5>
                  <h5 style={{ textAlign: "center" }}>
                    Tipo de Domicilio:{" "}
                    {selectedRecord.domicilioTipo.charAt(0).toUpperCase() +
                      selectedRecord.domicilioTipo.slice(1)}{" "}
                    - Horario Entrega: {selectedRecord.horarioEntrega}{" "}
                  </h5>
                  <h5 style={{ textAlign: "center" }}>
                    Nombre: {selectedRecord.nombreContacto} -Tel:{" "}
                    {selectedRecord.telefono}
                  </h5>
                  <h5>Observaciones: {selectedRecord.observaciones}</h5>
                </div>
                <hr
                  style={{
                    border: "1px solid black",
                    width: "100%",
                    background: "black",
                  }}
                />
                <div>
                  <table style={{ width: "100%", tableLayout: "fixed" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            paddingLeft: "5px",
                            verticalAlign: "top",
                          }}
                        >
                          <h5 style={{ textAlign: "center" }}>
                            <span
                              style={{
                                borderBottom: "3px solid",
                                paddingBottom: "0px",
                              }}
                            >
                              Tracking:
                            </span>{" "}
                          </h5>
                          <h5 style={{ textAlign: "center" }}>
                            {" "}
                            {selectedRecord.hashCode.slice(0, -4)}
                            <span
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                marginTop: "30px",
                              }}
                            >
                              {selectedRecord.hashCode.slice(-4)}
                            </span>
                          </h5>
                          <p>{selectedRecord.fechayhora}</p>
                        </td>
                        <td
                          style={{
                            width: "50%",
                            paddingRight: "5px",
                            textAlign: "center",
                            verticalAlign: "top",
                          }}
                        >
                          {/* <QRCode
                            value={JSON.stringify(selectedRecord.hashCode)}
                            size={128}
                          />{" "} */}
                          <div
                            style={{
                              position: "relative",
                              width: "128px",
                              height: "128px",
                            }}
                          >
                            {/* Generación del QR */}
                            <QRCode
                              value={JSON.stringify(selectedRecord.hashCode).slice(1, -1)} // Eliminar las comillas
                              size={128}
                              renderAs="svg"
                              style={{ position: "absolute", top: 0, left: 0 }}
                              level="H" // Corrección de errores al nivel más alto
                            />
                            {/* Imagen del logo en el centro */}
                            {/* <img
                              src={logoUrl}
                              alt="Logo"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                width: "30px",
                                height: "30px",
                                transform: "translate(-50%, -50%)",
                                border: "5px solid white",
                                borderRadius: "20%", // Esto hace que el logo sea circular, opcional
                              }}
                            /> */}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr
                    style={{
                      border: "1px solid black",
                      width: "100%",
                      background: "black",
                    }}
                  />
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "1px",
                      fontSize: "20px",
                      display: "flex",
                      justifyContent: "center",
                      gap: "1px",
                    }}
                  >
                    <button onClick={closeModal} className="btn-close">
                      Cerrar
                    </button>
                    <button onClick={() => window.print()}>Imprimir</button>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </Modal>
    </div>
  );
};

export default TiendaVentas;
